// Third party libraries
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AgGridModule } from 'ag-grid-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';
// None 'Ag1' components
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionPaneComponent } from './components/accordion/accordion-pane/accordion-pane.component';
import { AccordionHeaderComponent } from './components/accordion/accordion-header/accordion-header.component';
import { AccordionBodyComponent } from './components/accordion/accordion-body/accordion-body.component';
import { AccordionBodyHeaderComponent } from './components/accordion/accordion-body-header/accordion-body-header.component';
import { CartonGroupingPivotTableComponent } from '@app/account/pallets/carton-grouping-pivot-table/carton-grouping-pivot-table.component';
import { CompanySeasonPaginatorComponent } from './components/company-season-paginator/company-season-paginator.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InputModalComponent } from './components/input-modal/input-modal.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { MetabaseIframeComponent } from './components/metabase-iframe/metabase-iframe.component';
import { ModalBaseComponent } from './components/modal-base/modal-base.component';
import { ModalGalleryComponent } from './components/modal-gallery/modal-gallery.component';
import { OpenAgriComponentsModule } from '@openagri/components';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PillComponent } from './components/pill/pill.component';
import { PivotTableComponent } from './components/pivot-table/pivot-table.component';
import { RawPivotTableComponent } from './components/raw-pivot-table/raw-pivot-table.component';
import { WeekPickerComponent } from './components/week-picker/week-picker.component';
import { WeekPickerPopupComponent } from './components/week-picker/week-picker-popup/week-picker-popup.component';
import { YearPaginatorComponent } from './components/year-paginator/year-paginator.component';
// Directives components
import { Ag1ChipValueAccessorDirective } from './directives/ag1-chip-value-accessor.directive';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { IframeResizerDirective } from './directives/iframe-resizer.directive';
import { LineItemEditableFieldDirective } from './directives/line-item-editable-field.directive';
import { MenuPositionDirective } from './directives/menu-position.directive';
import { ModalHostDirective } from './directives/modal-host.directive';
import { TooltipAttributeDirective } from './directives/tooltip-attribute.directive';
// Pipe components
import { EmptyPipe } from './pipes/empty.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FullUrlPipe } from './pipes/full-url.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { HumanizePipe } from '@app/core/pipes/humanize.pipe';
import { MajorUnitPipe } from './pipes/major-unit.pipe';
import { PackingWeekDatePipe } from './pipes/packing-week-date.pipe';
import { PlurizePipe } from './pipes/plurize.pipe';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';

import { PriceBreakdownComponent } from './price-breakdown/price-breakdown.component';
import { NetReturnGranularityPickerComponent } from './net-return-granularity-picker/net-return-granularity-picker.component';
import { NotificationComponent } from './notification/notification.component';
import { ToolbarService } from './services/toolbar/toolbar.service';
import { SpinnerComponent } from './spinner/spinner.component';
// Shared components
import { PageLoadingPlaceholderComponent } from '@app/shared/page-loading-placeholder/page-loading-placeholder.component';
import { PivotTableColumnLayoutComponent } from '@app/shared/pivot-table-column-layout/pivot-table-column-layout.component';
import { ShimmerLoaderComponent } from '@app/shared/shimmer-loader/shimmer-loader.component';
// Ag1 Components
import { Ag1AccordionComponent } from './components/ag1-accordion/ag1-accordion.component';
import { Ag1BadgeComponent } from './components/ag1-badge/ag1-badge.component';
import Ag1ButtonComponent from './components/ag1-button/ag1-button.component';
import Ag1ButtonSmallComponent from './components/ag1-button-small/ag1-button-small.component';
import { Ag1CommentComponent } from './components/ag1-comment/ag1-comment.component';
import Ag1DropdownButtonComponent from './components/ag1-dropdown-button/ag1-dropdown-button.component';
import { Ag1EmailModalComponent } from './components/ag1-email-modal/ag1-email-modal.component';
import Ag1FilterGroupComponent from './components/ag1-filter-group/ag1-filter-group.component';
import Ag1FilterComponent from './components/ag1-filter-group/ag1-filter/ag1-filter.component';
import { Ag1InfoComponent } from './components/ag1-info/ag1-info.component';
import { Ag1ListSearchComponent } from './components/ag1-list-search/ag1-list-search.component';
import Ag1LoaderComponent from './components/ag1-loader/ag1-loader.component';
import {
  Ag1MenuComponent,
  Ag1ModalContentComponent,
} from './components/ag1-menu/ag1-menu.component';
import Ag1NotificationComponent from './components/ag1-notification/ag1-notification.component';
import Ag1PaginationComponent from './components/ag1-pagination/ag1-pagination.component';
import Ag1PillGroupComponent from './components/ag1-pill-group/ag1-pill-group.component';
import Ag1PillComponent from './components/ag1-pill-group/ag1-pill/ag1-pill.component';
import { FieldDrawerComponent } from './components/pivot-table/field-drawer/field-drawer.component';
import { PivotChartComponent } from './components/pivot-table/pivot-chart/pivot-chart.component';
import { ToolbarComponent } from './components/pivot-table/toolbar/toolbar.component';
import Ag1SearchComponent from './components/ag1-search/ag1-search.component';
import Ag1TabComponent from './components/ag1-tab/ag1-tab.component';
import Ag1TableComponent from './components/ag1-table/ag1-table.component';
import { Ag1TableBaseComponent } from './components/ag1-table/components/ag1-table-base/ag1-table-base.component';
import { Ag1TableDownloadComponent } from './components/ag1-table/components/ag1-table-download/ag1-table-download.component';
import { Ag1TableColumnGroupingComponent } from './components/ag1-table/components/ag1-table-column-grouping/ag1-table-column-grouping.component';
import { Ag1TableColumnSelectionComponent } from './components/ag1-table/components/ag1-table-column-selection/ag1-table-column-selection.component';
import { Ag1TagComponent } from './components/ag1-tag/ag1-tag.component';
import Ag1TitleComponent from './components/ag1-title/ag1-title.component';
import Ag1ToastNotificationComponent from './components/ag1-toast-notification/ag1-toast-notification.component';
import { Ag1UploadModalComponent } from './components/ag1-upload-modal/ag1-upload-modal.component';
import { Ag1WeekPickerComponent } from './components/ag1-week-picker/ag1-week-picker.component';
import { Ag1DateRangeComponent } from './components/ag1-date-range/ag1-date-range.component';
// Ag1, but not ag1, components; Probably needs rename
import { Ag1GridComponent } from './components/ag1-grid/ag1-grid.component';
import { Ag1GridLoadingOverlayComponent } from './components/ag1-grid/ag1-grid-loading-overlay.component';
import { Ag1ChartComponent } from './components/ag1-chart/ag1-chart.component';
import { Ag1KpiComponent } from './components/ag1-kpi/ag1-kpi.component';

import Ag1ViewConfiguration from './components/ag1-view-configuration/ag1-view-configuration.component';
import Ag1MillerColumnComponent from './components/ag1-miller-column/ag1-miller-column.component';
import Ag1ButtonBaseComponent from './components/ag1-button-base/ag1-button-base.component';
import { Ag1ChipComponent } from './components/ag1-chip/ag1-chip.component';
import { Ag1PanelComponent } from './components/ag1-panel/ag1-panel.component';
import { Ag1BannerComponent } from './components/ag1-banner/ag1-banner.component';
@NgModule({
  imports: [
    AgChartsAngularModule,
    AgGridModule,
    AngularSvgIconModule,
    CommonModule,
    DragDropModule,
    DropzoneModule,
    FormsModule,
    FontAwesomeModule,
    HighchartsChartModule,
    NgChartsModule,
    NgSelectModule,
    OpenAgriComponentsModule,
    OverlayModule,
    PortalModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    Ag1AccordionComponent,
    Ag1BadgeComponent,
    Ag1BannerComponent,
    Ag1ButtonBaseComponent,
    Ag1ButtonComponent,
    Ag1ButtonSmallComponent,
    Ag1ChartComponent,
    Ag1ChipComponent,
    Ag1ChipValueAccessorDirective,
    Ag1KpiComponent,
    Ag1CommentComponent,
    Ag1DateRangeComponent,
    Ag1DropdownButtonComponent,
    Ag1EmailModalComponent,
    Ag1FilterComponent,
    Ag1FilterGroupComponent,
    Ag1GridComponent,
    Ag1GridLoadingOverlayComponent,
    Ag1InfoComponent,
    Ag1ListSearchComponent,
    Ag1LoaderComponent,
    Ag1MenuComponent,
    Ag1MillerColumnComponent,
    Ag1ModalContentComponent,
    Ag1NotificationComponent,
    Ag1PaginationComponent,
    Ag1PanelComponent,
    Ag1PillGroupComponent,
    Ag1PillComponent,
    Ag1SearchComponent,
    Ag1TabComponent,
    Ag1TableBaseComponent,
    Ag1TableComponent,
    Ag1TableColumnGroupingComponent,
    Ag1TableColumnSelectionComponent,
    Ag1TableDownloadComponent,
    Ag1TagComponent,
    Ag1TitleComponent,
    Ag1ToastNotificationComponent,
    Ag1UploadModalComponent,
    Ag1ViewConfiguration,
    Ag1WeekPickerComponent,
    AccordionBodyComponent,
    AccordionBodyHeaderComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionPaneComponent,
    CartonGroupingPivotTableComponent,
    ClickedOutsideDirective,
    CompanySeasonPaginatorComponent,
    ConfirmModalComponent,
    CurrencyPipe,
    DropdownComponent,
    EmptyPipe,
    FieldDrawerComponent,
    FilterPipe,
    FullUrlPipe,
    HasPermissionDirective,
    HighlightPipe,
    HumanizePipe,
    IframeResizerDirective,
    ImageSliderComponent,
    InputModalComponent,
    LineItemEditableFieldDirective,
    MajorUnitPipe,
    MenuPositionDirective,
    MetabaseIframeComponent,
    ModalBaseComponent,
    ModalGalleryComponent,
    ModalHostDirective,
    NetReturnGranularityPickerComponent,
    NotificationComponent,
    PackingWeekDatePipe,
    PageLoadingPlaceholderComponent,
    PaginatorComponent,
    PillComponent,
    PivotChartComponent,
    PivotTableColumnLayoutComponent,
    PivotTableComponent,
    PlurizePipe,
    PrettyPrintPipe,
    PriceBreakdownComponent,
    RawPivotTableComponent,
    ReplacePipe,
    SafeUrlPipe,
    ShimmerLoaderComponent,
    SortPipe,
    SpinnerComponent,
    TimeAgoPipe,
    ToolbarComponent,
    TooltipAttributeDirective,
    TruncatePipe,
    TypeofPipe,
    WeekPickerComponent,
    WeekPickerPopupComponent,
    YearPaginatorComponent,
  ],
  exports: [
    Ag1AccordionComponent,
    Ag1BadgeComponent,
    Ag1BannerComponent,
    Ag1ButtonBaseComponent,
    Ag1ButtonComponent,
    Ag1ButtonSmallComponent,
    Ag1ChartComponent,
    Ag1ChipComponent,
    Ag1CommentComponent,
    Ag1DateRangeComponent,
    Ag1DropdownButtonComponent,
    Ag1EmailModalComponent,
    Ag1FilterComponent,
    Ag1FilterGroupComponent,
    Ag1GridLoadingOverlayComponent,
    Ag1GridComponent,
    Ag1InfoComponent,
    Ag1KpiComponent,
    Ag1ListSearchComponent,
    Ag1LoaderComponent,
    Ag1MenuComponent,
    Ag1MillerColumnComponent,
    Ag1NotificationComponent,
    Ag1PaginationComponent,
    Ag1PanelComponent,
    Ag1PillGroupComponent,
    Ag1PillComponent,
    Ag1SearchComponent,
    Ag1TabComponent,
    Ag1TableBaseComponent,
    Ag1TableColumnGroupingComponent,
    Ag1TableColumnSelectionComponent,
    Ag1TableComponent,
    Ag1TableDownloadComponent,
    Ag1TagComponent,
    Ag1TitleComponent,
    Ag1ToastNotificationComponent,
    Ag1UploadModalComponent,
    Ag1ViewConfiguration,
    Ag1WeekPickerComponent,
    AccordionBodyComponent,
    AccordionBodyHeaderComponent,
    AccordionComponent,
    AccordionHeaderComponent,
    AccordionPaneComponent,
    AngularSvgIconModule,
    CartonGroupingPivotTableComponent,
    ClickedOutsideDirective,
    CompanySeasonPaginatorComponent,
    ConfirmModalComponent,
    CurrencyPipe,
    DropdownComponent,
    EmptyPipe,
    FilterPipe,
    FullUrlPipe,
    HasPermissionDirective,
    HighlightPipe,
    HumanizePipe,
    IframeResizerDirective,
    ImageSliderComponent,
    InputModalComponent,
    LineItemEditableFieldDirective,
    MajorUnitPipe,
    MetabaseIframeComponent,
    ModalHostDirective,
    MenuPositionDirective,
    ModalBaseComponent,
    ModalGalleryComponent,
    NetReturnGranularityPickerComponent,
    NotificationComponent,
    PackingWeekDatePipe,
    PageLoadingPlaceholderComponent,
    PaginatorComponent,
    PivotTableColumnLayoutComponent,
    PivotTableComponent,
    PrettyPrintPipe,
    PriceBreakdownComponent,
    RawPivotTableComponent,
    ReplacePipe,
    SafeUrlPipe,
    ShimmerLoaderComponent,
    SortPipe,
    SpinnerComponent,
    TimeAgoPipe,
    TooltipAttributeDirective,
    TruncatePipe,
    TypeofPipe,
    WeekPickerComponent,
    YearPaginatorComponent,
  ],
  providers: [HumanizePipe, ToolbarService],
})
export class CoreModule {}
