<button
  class="
    {{ classOverrides }}
    ag1-button
    ag1-button--{{ variant }}
    flex
    items-center
    justify-center
    gap-3
  "
  [ngClass]="[
    tooltip.length > 1 ? 'ag1-tooltip' : '',
    position !== 'right' ? 'flex-row' : 'flex-row-reverse',
    isActive ? 'ag1-button--active' : ''
  ]"
  [attr.data-hint]="tooltip"
  [attr.data-position]="tooltipPosition"
  [attr.data-variant]="variant"
  [disabled]="disabled"
  (click)="onClick($event)"
>
  <ng-content></ng-content>
  <span *ngIf="label">
    {{ label }}
  </span>
</button>
