import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ag1-title',
  templateUrl: './ag1-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class Ag1TitleComponent {
  @Input()
  label = 'Title';
}
