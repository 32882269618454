export const environment = {
  production: false,
  staging: true,
  development: false,
  apiBaseUrl: 'https://staging-api.agrigateone.com',
  webSocketUrl: 'wss://staging-api.agrigateone.com/cable',
  authentication: {
    auth0Domain: 'agrigate-dev.eu.auth0.com',
    clientID: 'L3xaVVeBBc2T7CgUKzfKMZmxNxDCMbUL',
    redirectURI: 'https://staging-app.agrigateone.com/login/callback',
    audience: 'https://api-dev.agrigate.com',
    webappUrl: 'https://staging-app.agrigateone.com',
    logoutUrl: 'https://staging-app.agrigateone.com/login',
  },
  pusher: {
    key: 'e68976f08cd29559a59c',
    options: {
      cluster: 'eu',
      encrypted: true,
    },
  },
  sentry: {
    environment: 'staging',
    dsn: 'https://f992693bf9654428972bd8968d2a13fa@sentry.io/1365705',
    logToSentry: true,
  },
  freshdesk: {
    domain: 'agrigate',
  },
  gcp: {
    maps: {
      apiKey: 'AIzaSyBiJXMwNwB83uBb5yyaN-P6CamuCA-6ZOE',
    },
  },
  angulartics2Enabled: true,
};
